import type { FunctionComponent } from 'react'
import React, { Fragment, useContext } from 'react'
import { Checkbox, TypographyV2 as Typography } from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { dynamicDatalayerPush } from '@which/shared'

import classnames from 'classnames'

import { StarRating } from '../../../product-page/components/StarRating'
import { ComparisonTableContext } from '../../ComparisonTableContext'
import { TABLE_ROWS_TO_BE_REFINED } from '../../reducers/comparisonTableActionTypes'
import { totalProductColumns } from './ComparisonTable'
import styles from './ComparisonTable.module.scss'
import { FillerCells } from './FillerCells/FillerCells'
import type { OnTableRowCheckboxArgs } from './types'

export const ComparisonTableData: FunctionComponent<ComparisonTableDataProps> = ({
  taxonomySlug,
}) => {
  const {
    state: { tableData, refineStatus, rowsToBeRefined },
    dispatch,
  } = useContext(ComparisonTableContext)

  if (!tableData?.length) {
    return null
  }

  const onTableRowCheckbox = ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => {
    dispatch({
      type: TABLE_ROWS_TO_BE_REFINED,
      row: value,
      sectionLabel,
    })

    dynamicDatalayerPush({
      eventCategory: 'Refine Results',
      eventAction: `${taxonomySlug} - ${sectionLabel.toLowerCase()} - ${rowLabel.toLowerCase()}`,
      eventLabel: `${!rowsToBeRefined[sectionLabel]?.includes(value)}`,
    })
  }

  return (
    <>
      {tableData?.map(({ label: sectionLabel, rows, dataType }, sectionIndex) => (
        <Fragment key={`${sectionLabel}-${sectionIndex}`}>
          {rows.length > 0 && (
            <tr>
              {/* // should really be a td with colspan of 5 but if do that loses stickiness*/}
              <td className={classnames(styles.sectionHeader, styles.stickyFirstColumn)}>
                <Typography textStyle="sb-text-body-default-strong" tag="span">
                  {sectionLabel}
                </Typography>
              </td>
              <FillerCells count={totalProductColumns - 1} className={styles.sectionHeader} />
            </tr>
          )}
          {rows.map(({ rowLabel, rowValues }, rowIndex) => {
            return (
              <tr
                className={styles.productFeatureRow}
                key={`${rowLabel}-${rowIndex}`}
                data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
              >
                <td className={classnames(styles.tableDataRowHead, styles.stickyFirstColumn)}>
                  {refineStatus === 'refine' && (
                    <Checkbox
                      id={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                      name={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                      label={rowLabel}
                      value={rowLabel}
                      onChangeCallback={({ value }) =>
                        value && onTableRowCheckbox({ value, sectionLabel, rowLabel })
                      }
                    />
                  )}
                  {refineStatus !== 'refine' && (
                    <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
                      {rowLabel}
                    </Typography>
                  )}
                </td>
                {rowValues.map(({ value }, valIndex) => (
                  <td className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                    {getValueElement(value, dataType)}
                  </td>
                ))}
                <FillerCells
                  className={styles.tableDataRowValue}
                  count={totalProductColumns - rowValues.length}
                />
              </tr>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

type ComparisonTableDataProps = {
  taxonomySlug: string
}

const getValueElement = (value, dataType) => {
  if (value === '') {
    return (
      <div className={styles.iconContainer}>
        <SolidPadlockIcon data-testid="padlock-icon" />
      </div>
    )
  }

  return (
    <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
      {dataType === 'test-result' ? <StarRating value={value} /> : value}
    </Typography>
  )
}
