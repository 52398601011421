import type { FunctionComponent, ReactChild } from 'react'
import React from 'react'
import { AccordionItem as SBAccordionItem } from '@which/seatbelt'

export const AccordionItem: FunctionComponent<Props> = ({ children, label, id }) => (
  <SBAccordionItem key={id} content={children} label={label} />
)

///////// IMPLEMENTATION /////////

type Props = {
  children: ReactChild
  label: string
  id: string
}
