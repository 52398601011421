export function get(object: Record<any, any>, path: string | Array<string>, defaultValue?: any) {
  // Convert the path to an array if it's not already
  const pathArray = Array.isArray(path) ? path : path.split(/[,[\].]+?/).filter(Boolean)

  let result = object

  // Loop through the path array and return the value at the end
  for (const key of pathArray) {
    result = result != null ? result[key] : undefined
    if (result === undefined) {
      return defaultValue
    }
  }
  return result
}
