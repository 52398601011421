import type { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import type { ProductScoreGaugeProps } from '@which/seatbelt'
import {
  ButtonLink,
  CardRow,
  Grid,
  GridItem,
  Heading,
  Link,
  ProductCardPortrait,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser'
import parse, { domToReact, Element } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'

import type { ProductHubPage } from '../../../../generated/frontend'
import { filterBadgeThemes } from '../../../../shared'
import styles from './ProductHubReviews.module.scss'

export const ProductHubReviewsTemplate: FunctionComponent<ProductHubReviewsProps> = ({
  productReviews,
}) => {
  const history = useHistory()

  const { productSlug, subText, productData, heading } = productReviews

  const parseReviewsSubText = () => {
    if (!subText) {
      return null
    }

    const sanitizedHTML = DOMPurify.sanitize(subText)
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element && domNode.name === 'a') {
          const { href } = domNode.attribs
          return (
            <Link href={href} data-testid="standard-link-primary">
              {domToReact(domNode.children as DOMNode[])}
            </Link>
          )
        }
      },
    }
    return (
      <Typography textStyle="sb-text-heading-subheading" className={styles.reviewsStandfirst}>
        {parse(sanitizedHTML, options)}
      </Typography>
    )
  }

  return (
    <section aria-label={heading} className={styles.reviewsWrapper} id="product-reviews">
      <Grid className={styles.grid} includeGutters={false}>
        <GridItem>
          <Heading heading={heading} headingTag="h2" headingType="large" />
          {parseReviewsSubText()}
        </GridItem>
        <GridItem>
          <CardRow className={classnames(styles.reviewsCardRow, styles.reviewsCardRowNew)}>
            {productData.map(
              (
                {
                  image,
                  manufacturer,
                  model,
                  tags,
                  price,
                  productScoreGauge,
                  retailers,
                  slug,
                  badges,
                  variantPriceRange,
                  variantValues,
                  businessKey,
                },
                index
              ) => {
                const url = `/reviews/${productSlug}/${slug}`

                const variantMinPrice = variantPriceRange?.min?.formatted
                const variantMaxPrice = variantPriceRange?.max?.formatted
                const formattedPrice =
                  variantMinPrice !== variantMaxPrice && variantValues
                    ? `${variantMinPrice} - ${variantMaxPrice}`
                    : price

                return (
                  <ProductCardPortrait
                    key={index}
                    className={styles.reviewsProductCard}
                    image={image}
                    manufacturer={manufacturer}
                    model={model}
                    price={formattedPrice}
                    retailers={retailers}
                    slug={slug}
                    tags={tags}
                    badges={filterBadgeThemes(badges)}
                    productScoreGauge={productScoreGauge as ProductScoreGaugeProps}
                    cardCallback={() => {
                      dynamicGa4DataLayerPush({
                        event: 'click_card',
                        item_text: `${manufacturer} ${model}`,
                        item_url: url,
                        item_parent_text: 'Product Reviews',
                        item_index: index + 1,
                      })
                      history.push(url)
                    }}
                    productUrl={url}
                    taxonomySlug={productSlug}
                    businessKey={businessKey}
                    handleAddCompare={() => null}
                    handleRemoveCompare={() => null}
                  />
                )
              }
            )}
          </CardRow>
          <div className={styles.reviewsButtonWrapper}>
            <ButtonLink
              href={`/reviews/${productSlug}`}
              appearance="primary"
              data-which-id="producthub-button"
            >
              <ChevronRightIcon />
              {`View all ${heading}`}
            </ButtonLink>
          </div>
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

export type ProductHubReviewsProps = Pick<ProductHubPage, 'productReviews'>
