import type { FormEvent } from 'react'
import React, { useState } from 'react'
import {
  Button,
  FormField,
  InputV2,
  Link,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import styles from './BroadbandSwitchCTA.module.scss'
import { defaultImage } from './constants'

interface Props {
  title?: string
  description?: string
  offerText?: string
  smallPrint?: { text: string; link: string }
  image?: ImageWithSources
}

export const BroadbandSwitchCTA: React.FC<Props> = ({
  title = 'Compare broadband deals',
  description = 'Use Which? to search for faster, more reliable broadband services.',
  offerText = 'Members who switch to Zen Internet before 25 September get a £100 Amazon.co.uk gift card.',
  smallPrint = {
    text: 'Offer ends 25 September 2024. ',
    link: 'https://signup.which.co.uk/broadband_offer_aug24',
  },
  image,
}) => {
  const [postcode, setPostcode] = useState<string>('')
  const imageData = image ?? defaultImage

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    window.open(
      `https://broadband.which.co.uk/broadband-deals?postcode=${postcode}&utm_source=which&utm_medium=advice&utm_campaign=zen_member_excl_sep24`,
      '_self'
    )
  }

  return (
    <aside aria-labelledby="broadband-cta-heading" className={styles.container}>
      <div className={styles.content}>
        <Typography
          className={styles.heading}
          id="broadband-cta-heading"
          textStyle="sb-text-heading-small"
        >
          {title}
        </Typography>
        <Typography textStyle="sb-text-body-default-regular">{description}</Typography>
        <Typography textStyle="sb-text-body-default-strong">{offerText}</Typography>
        <form
          className={styles.form}
          data-which-id="compare-broadband-widget-form"
          name="broadband-switch"
          onSubmit={handleSubmit}
          title={title}
        >
          <FormField
            label="Postcode"
            labelFor="broadband-switch-postcode"
            showRequiredText={false}
            className={styles.postcodeField}
          >
            <InputV2
              id="broadband-switch-postcode"
              name="broadband-switch-postcode"
              placeholder="eg. NW1 4DF"
              value={postcode}
              onChange={(event) => setPostcode(event.target.value)}
            />
          </FormField>
          <Button type="submit">Compare</Button>
          <Typography textStyle="sb-text-body-x-small-regular">
            {smallPrint.text}
            <Link href={smallPrint.link} className={styles.smallPrintLink}>
              T&Cs apply
            </Link>
          </Typography>
        </form>
      </div>
      {imageData && (
        <Link
          href={`https://broadband.which.co.uk/broadband-deals?postcode=${postcode}&utm_source=which&utm_medium=advice&utm_campaign=zen_member_excl_sep24`}
          className={styles.imageWrapper}
        >
          <Picture
            src={imageData.src}
            alt={imageData.alt}
            sources={imageData.sources}
            imageClassName={styles.brandImageBg}
          />
        </Link>
      )}
    </aside>
  )
}
