import type { ReactElement } from 'react'
import { Hr, Typography } from '@which/seatbelt'
import { ArrowLeftIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import { Link } from '../../../../shared/components/Link'
import style from './BundleDetails.module.scss'

export const BundleDetails = (props: { bundleId: string }) => {
  const { bundleId } = props
  const bundleConfig = bundleConfigs[bundleId]

  if (bundleConfig === undefined) {
    console.warn('Trying to show details of a non-existent bundle - skipping')
    return null
  }

  const { title, descriptionElement } = bundleConfig

  return (
    <div className={style.bundleDetailsSelectedSubscription}>
      <Link
        icon={ArrowLeftIcon}
        includeIcon={true}
        containIcon
        href="https://join.which.co.uk/join/subscribe"
      >
        Choose your membership
      </Link>
      <Typography tag="h2" textStyle="sb-text-heading-page-title">
        Join Which?
      </Typography>
      <Typography tag="h3" textStyle="sb-text-heading-small">
        {title}
      </Typography>
      <Typography tag="p" textStyle="sb-text-interface-body-default-regular">
        {descriptionElement}
      </Typography>
      <Hr fullWidth={true} />
    </div>
  )
}

const bundleConfigs: Record<
  string,
  { title: string; descriptionElement: ReactElement } | undefined
> = {
  FULLM5001099: {
    title: 'Full Access Monthly',
    descriptionElement: (
      <>
        <b>£5 for first month</b>, then £10.99 a month
      </>
    ),
  },
  DIGITALM899: {
    title: 'Digital Monthly',
    descriptionElement: <b>£8.99 a month</b>,
  },
  PRINTM899: {
    title: 'Which? Magazine Monthly',
    descriptionElement: <b>£8.99 a month</b>,
  },
}
