import React, { useCallback, useState } from 'react'
import { Table as t } from '@which/seatbelt'

import type { ShowMoreContentRenderer } from '../ShowMore/ShowMore'
import { SinglePadlockCta } from '../SinglePadlockCta'
import styles from './CsvTable.module.scss'
import type { ArticleElement, Props, TableBodyContent } from './CsvTable'
import { extendElementProps, getCellAlignment, getInitialTableData, getVariantSettings, isValidRowItem } from './utils'
import { CsvTableState, Heading } from './CsvTableUnlocked'

export const CsvTableLocked: React.FC<Props> = (data) => {
  const { testVariant, ...tableData } = data
  const defaultTableData = getInitialTableData(tableData)
  const [sortableTableData] = useState<CsvTableState>(defaultTableData)
  const { headings, sortedTableBody } = sortableTableData

  const tableRenderer = useCallback<ShowMoreContentRenderer>(
    (children) => {
      const bodyContent = children as TableBodyContent[][]

      const { colSpan, rowSpan } = getVariantSettings(testVariant, bodyContent)

      return (
        <t.Table tableClassName="tableVariation" tableId={tableData.id}>
          <t.Head>
            <t.Row>
              {Object.values(headings).map((heading: Heading, columnIndex: number) => {
                return <t.Header key={`header_${columnIndex}`}>{heading.value}</t.Header>
              })}
            </t.Row>
          </t.Head>

          {testVariant === 'scoresLocked' ? (
            <t.Body>
              {bodyContent.map((row, rowIndex) => {
                const [firstItem] = row

                if (!isValidRowItem(firstItem)) {
                  return null
                }

                return (
                  <t.Row key={`row_${rowIndex}`}>
                    {row.map((bodyRowData: string | ArticleElement, cellIndex) => {
                      if (cellIndex === 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            align={getCellAlignment(bodyRowData)}
                          >
                            {typeof bodyRowData === 'string'
                              ? bodyRowData
                              : tableData.renderArticleElements(
                                  extendElementProps({
                                    bodyContent,
                                    rowIndex,
                                    bodyRowData,
                                    cellIndex,
                                    row,
                                  })
                                )}
                          </t.Cell>
                        )
                      }
                      if (rowIndex === 0 && cellIndex === 1) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            className="lockCtaCell scoresLocked"
                          >
                            <SinglePadlockCta type="desktop" />
                          </t.Cell>
                        )
                      }
                    })}
                  </t.Row>
                )
              })}
            </t.Body>
          ) : (
            <t.Body>
              {bodyContent.map((row, rowIndex) => {
                const [firstItem] = row

                if (!isValidRowItem(firstItem)) {
                  return null
                }

                return (
                  <t.Row key={`row_${rowIndex}`}>
                    {row.map((bodyRowData: string | ArticleElement, cellIndex) => {
                      if (rowIndex === 0 && cellIndex === 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            colSpan={colSpan}
                            rowSpan={rowSpan}
                            className="lockCtaCell"
                          >
                            <SinglePadlockCta type="desktop" />
                          </t.Cell>
                        )
                      }

                      if (cellIndex > 0) {
                        return (
                          <t.Cell
                            key={`cell_${rowIndex}_${cellIndex}`}
                            align={getCellAlignment(bodyRowData)}
                          >
                            {typeof bodyRowData === 'string'
                              ? bodyRowData
                              : tableData.renderArticleElements(
                                  extendElementProps({
                                    bodyContent,
                                    rowIndex,
                                    bodyRowData,
                                    cellIndex,
                                    row,
                                  })
                                )}
                          </t.Cell>
                        )
                      }
                    })}
                  </t.Row>
                )
              })}
            </t.Body>
          )}
        </t.Table>
      )
    },
    [headings, tableData, testVariant]
  )

  return (
    <div className={styles.csvTable}>
      <>
        {tableRenderer(sortedTableBody)}
        <SinglePadlockCta />
      </>

      {tableData?.notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: tableData?.notes || '' }}
        />
      )}
    </div>
  )
}
