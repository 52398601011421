import React from 'react'
import { Heading } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { Link as LinkType } from '../../../generated/frontend'
import { Link } from '../Link'
import { ShowMore } from '../ShowMore'
import type { ShowMoreContentRenderer } from '../ShowMore/ShowMore'
import type { Props } from './AssociatedArticles'
import styles from './AssociatedArticlesWithShowMore.module.scss'

const itemClickHandler = (event: string, text: string, position: string) =>
  dynamicGa4DataLayerPush({
    event: event,
    item_text: text,
    item_parent_text: 'Broadband provider reviews',
    item_spec: position,
    item_group: 'streamline cta',
    utagid: 'PNJ192DP01',
  })

const ListItem: React.FC<LinkType & { onClick: (articleUrl: string) => void }> = ({
  text,
  href,
  onClick,
}) => (
  <li className={styles.listItem}>
    <Link href={href} onClick={onClick} linkStyle="secondary" type="NavigationLink">
      {text}
    </Link>
  </li>
)

export const AssociatedArticlesWithShowMore: React.FC<Props> = ({
  links,
  title,
  position,
  className,
}) => {
  if (!links?.length || !title) {
    return null
  }

  const contentRenderer: ShowMoreContentRenderer = (linkElements) => {
    if (!Array.isArray(linkElements)) {
      return linkElements
    }

    const isExpanded = linkElements.length > 6

    if (isExpanded) {
      return <ul className={styles.list}>{linkElements}</ul>
    }

    return (
      <ul className={styles.list}>
        {links.slice(0, 6).map(({ href, text }) => (
          <ListItem
            key={`${text}-${href}`}
            href={href}
            text={text}
            onClick={() => itemClickHandler('click_cta', text, position)}
          />
        ))}
      </ul>
    )
  }

  return (
    <div
      className={classnames('associated-articles', className)}
      data-testid="associated-article-links-wrapper"
    >
      {title && <Heading heading={title} headingTag="h2" headingType="small" />}
      <ShowMore
        initialCount={6}
        contentRenderer={contentRenderer}
        moreButtonProps={{ align: 'center' }}
        callback={() => itemClickHandler('click_button', 'Show more', position)}
      >
        {links.map(({ href, text }) => (
          <ListItem
            key={`${text}-${href}`}
            href={href}
            text={text}
            onClick={() => itemClickHandler('click_cta', text, position)}
          />
        ))}
      </ShowMore>
    </div>
  )
}
