import type { FunctionComponent } from 'react'
import React, { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'

import loadable from '@loadable/component'
import newrelic from 'newrelic'

import { Loader } from '../../shared/components/Loader'
import { PagePropsContext } from '../../shared/usePageProps'
import type { PageProps } from '../'

export const Page: FunctionComponent<PageProps> = ({
  pagePath,
  template,
  rootCollectionName,
  context,
}) => {
  const MatchedPage = useMemo(
    () =>
      loadable(
        () =>
          import(
            /* webpackInclude: /[a-zA-Z\-]*\/[a-zA-Z]*Page\.tsx$/ */ `../../pages/${pagePath}`
          ),
        {
          fallback: <Loader />,
        }
      ),
    [pagePath]
  )

  const { data: { userAccessState } = {} } = useQuery(USER_ACCESS_STATE_QUERY)

  if (typeof newrelic !== 'undefined') {
    newrelic.setTransactionName(pagePath)
  }

  return (
    <PagePropsContext.Provider
      value={{
        template,
        rootCollectionName,
        context,
        userAccessState,
      }}
    >
      <MatchedPage />
    </PagePropsContext.Provider>
  )
}

export const USER_ACCESS_STATE_QUERY = gql`
  query userAccessState {
    userAccessState {
      userLoggedIn
      transformTypeDecision
    }
  }
`
