import React, { useEffect, useState } from 'react'
import { dynamicGa4DataLayerPush, getCookie } from '@which/shared'

import { v4 as uuidv4 } from 'uuid'

import { useFullUrl } from '../../hooks/useFullUrl'
import { Link } from '../Link'

export const TrackonomicsLink: React.FC<Props> = ({
  href,
  contentType,
  taxonomyHierarchy,
  optionalTracking,
  useLinkComponent = true,
  children,
  className,
  onClick,
}) => {
  const SITE_CODE = 'which_c9990_which'
  const PLATFORM = 'web'
  const { vertical, subVertical, category, superCategory } = taxonomyHierarchy

  const { getFullUrl } = useFullUrl()

  const [userId, setUserId] = useState<string>('')
  const [clickId, setClickId] = useState<string | null>(null)
  const [pendingClickId, setPendingClickId] = useState<string>(uuidv4())

  const handleAffiliateClick = () => {
    const clickText = getDeepestChildString(children)

    dynamicGa4DataLayerPush({
      event: 'trackonomics-click',
      item_url: href,
      item_text: clickText,
      affiliate_click_id: clickId,
      ...optionalTracking,
    })

    setPendingClickId(uuidv4())

    if (onClick !== undefined) {
      onClick()
    }
  }

  useEffect(() => {
    setClickId(pendingClickId)
  }, [pendingClickId])

  useEffect(() => {
    const cookieID = getCookie(document.cookie, 'master_id')
    setUserId(cookieID.length > 0 ? cookieID : getCookie(document.cookie, 'identity.cdn.logged_in'))
  }, [])

  const getQueryParams = (definedClickId: string | null) => ({
    q: href,
    p: getFullUrl(),
    event_type: 'click',
    userid: userId ?? '',
    clickid: definedClickId ?? '',
    content_type: contentType,
    vertical,
    sub_vertical: subVertical,
    category,
    super_category: superCategory,
    platform: PLATFORM,
    ...optionalTracking,
  })

  const getUrl = (definedClickId: string | null): string => {
    const url = new URL(`/xid/${SITE_CODE}`, 'https://clicks.trx-hub.com')

    for (const [key, value] of Object.entries(getQueryParams(definedClickId))) {
      url.searchParams.set(key, value)
    }

    return url.toString()
  }

  const props = {
    href: getUrl(clickId),
    'data-testid': 'trackonomics-link',
    'data-which-id': 'affiliate-link',
    onClick: () => handleAffiliateClick(),
    className: className,
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  }

  return useLinkComponent ? <Link {...props}>{children}</Link> : <a {...props}>{children}</a>
}

///////// IMPLEMENTATION /////////
type ContentType = 'article' | 'product page' | 'shop home'

interface TaxonomyHierarchy {
  vertical: string
  subVertical: string
  category: string
  superCategory: string
}

export interface Props {
  href: string
  contentType: ContentType
  taxonomyHierarchy: TaxonomyHierarchy
  optionalTracking?: Record<string, string>
  useLinkComponent?: boolean
  className?: string
  onClick?: () => void
}

const getDeepestChildString = (element: React.ReactNode): string => {
  if (Array.isArray(element)) {
    return getDeepestChildString(element[0])
  }

  if (typeof element === 'string') {
    return element
  }

  if (element === null || typeof element !== 'object' || !('props' in element)) {
    return ''
  }

  return getDeepestChildString(element?.props?.children)
}
