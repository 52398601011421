export const devices = {
  device0: 'Chromebooks',
  device1: 'Desktop',
  device2: 'Laptop',
  device3: 'Mobiles',
  device4: 'Printer',
  device5: 'Smart Tech (Amazon Alexa etc)',
  device6: 'Smart TV',
  device7: 'Tablet',
}

export const operatingSystems = [
  'Android 11',
  'Android 12',
  'Android 13',
  'Android 14',
  'Apple iOS 13',
  'Apple iOS 14',
  'Apple iOS 15',
  'Apple iOS 16',
  'Chrome OS',
  'Linux',
  'MacOS Ventura',
  'MacOS Monterey',
  'MacOS Big Sur',
  'MacOS Sonoma',
  'Windows 10',
  'Windows 11',
  'Other',
]

export const topics = {
  topic0: {
    name: 'Buying Advice',
    problems: {
      problem0: 'Audio Equipment',
      problem1: 'Anti Virus Software',
      problem2: 'All-in-one Computers',
      problem3: 'Broadband',
      problem4: 'Cloud storage',
      problem5: 'Desktop',
      problem6: 'Mobile',
      problem7: 'Peripherals',
      problem8: 'Printers & Scanners',
      problem9: 'Laptops',
      problem10: 'Networking Equipment',
      problem11: 'Smart Tech',
      problem12: 'Smart TVs',
      problem13: 'Storage Devices',
      problem14: 'Tablet',
    },
  },
  topic1: {
    name: 'Email issues',
    problems: {
      problem0: "Can't access email account",
      problem1: "Can't Send or receive emails",
      problem2: 'Contact or Calendar',
      problem3: 'Email Account',
      problem4: 'Possible email account breach',
      problem5: 'Problems with email program',
      problem6: 'Reset password (for email account)',
      problem7: 'Spam',
      problem8: 'Sync Issues',
    },
  },
  topic2: {
    name: 'File, Data or Storage',
    problems: {
      problem0: 'Backing up to external drive',
      problem1: 'Cloud storage',
      problem2: 'Data loss',
      problem3: 'Data transfer',
      problem4: 'Network attached storage / Share',
      problem5: 'Recover data',
      problem6: 'Removing files',
      problem7: 'Storage issues / freeing up space',
      problem8: 'Upgrade drive',
      problem9: 'Working with files',
    },
  },
  topic3: {
    name: 'General Computer Problems',
    problems: {
      problem0: 'Accessibility help',
      problem1: 'Connection problems',
      problem2: 'Device not powering on or booting up',
      problem3: 'Help setting up new device',
      problem4: 'External drive connection issues',
      problem5: 'Internal drive connection issues',
      problem6: 'Keyboard, mouse or trackpad issues',
      problem7: 'Screen & Display issues',
      problem8: 'USB stick connection issues',
      problem9: 'Physical damage to the device',
      problem10: 'Microphone or Webcam issues',
      problem11: 'No Sound',
      problem12: 'Locked out of PC',
      problem13: 'Slow computers',
      problem14: 'Performance issues',
    },
  },
  topic4: {
    name: 'Hardware Issues',
    problems: {
      problem0: 'Battery Issues',
      problem1: "Desktop or All-In-One PC's",
      problem2: 'Laptop issues',
      problem3: 'Tablet issues',
      problem4: 'TV Boxes',
      problem5: 'Printer issues',
      problem6: 'Smartphones',
      problem7: 'Smart TV issues',
    },
  },
  topic5: {
    name: 'Internet & Networking',
    problems: {
      problem0: 'Choosing broadband',
      problem1: 'Connection problems',
      problem2: 'Extending wifi signal',
      problem3: 'Home networking',
      problem4: 'Network configuration',
      problem5: 'Router / Access point setup',
      problem6: 'Router security',
      problem7: 'Slow internet',
      problem8: 'VPN issues',
    },
  },
  topic6: {
    name: 'Printers & Ink',
    problems: {
      problem0: 'Cartridge / ink problems',
      problem1: 'Error messages',
      problem2: 'Misaligned or incorrect print-outs',
      problem3: 'Paper Jam',
      problem4: 'Printer apps',
      problem5: 'Printer drivers',
      problem6: 'Setup printer',
      problem7: 'Software Issues',
      problem8: 'Unable to connect printer to network / wi-fi',
      problem9: 'Unable to print or scan',
    },
  },
  topic7: {
    name: 'Security & Privacy',
    problems: {
      problem0: 'Advice or help with manufacture security updates',
      problem1: 'Antivirus and security software',
      problem2: 'Online Privacy',
      problem3: 'Pop-ups or adverts',
      problem4: 'Potential hack',
      problem5: 'Potential Virus',
      problem6: 'Scams, Incl. Phishing & scam emails',
      problem7: 'Security best practices',
      problem8: 'Web browser has been hijacked',
    },
  },
  topic8: {
    name: 'Software & Programs',
    problems: {
      problem0: 'Apps',
      problem1: 'Drivers',
      problem2: 'Password managers',
      problem3: 'Software installation',
      problem4: 'Software removal',
      problem5: 'Microsoft Office 2016 above',
      problem6: 'Operating system problems',
      problem7: 'Updating operating system',
      problem8: 'Virtualisation',
      problem9: 'Web browser Issues',
    },
  },
  topic9: {
    name: 'Usage',
    problems: {
      problem0: 'Using your computer',
      problem1: 'Working with files',
    },
  },
}
