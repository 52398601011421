import React from 'react'
import { ArticleCard, CardRow } from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import type { Author } from '../../../generated/frontend'

export interface Props {
  name?: string
  articles: {
    title: string
    href: string
    imageWithSources?: ImageWithSources
    authors?: Author[]
  }[]
  viewAllData?: {
    text: string
    href: string
  }
}

export const LatestNewsCards: React.FC<Props> = ({ name, viewAllData, articles = [] }) => {
  if (articles.length === 0) {
    return null
  }

  return (
    <CardRow
      title={name}
      text={viewAllData?.text ?? ''}
      link={{
        text: viewAllData?.text ?? '',
        href: viewAllData?.href ?? '',
      }}
    >
      {articles.map(({ title, href, imageWithSources, authors = [] }, index) => (
        <ArticleCard
          key={`${title}-${index}`}
          href={href ?? ''}
          title={title}
          image={imageWithSources?.src}
          imageSources={imageWithSources?.sources}
          imageAlt={imageWithSources?.alt}
          authorName={authors[0]?.name}
          authorImage={authors[0]?.image?.src}
          display="scroll-row"
        />
      ))}
    </CardRow>
  )
}
