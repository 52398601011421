import React from 'react'
import type { Link } from 'react-router-dom'
import type { LinkProps } from '@which/seatbelt'
import { LinkChildren } from '@which/seatbelt'
import linkStyles from '@which/seatbelt/src/components/Link/Link.module.scss'
import baseLinkStyles from '@which/seatbelt/src/components/Links/BaseLink/BaseLink.module.scss'
import navigationLinkStyles from '@which/seatbelt/src/components/Links/NavigationLink/NavigationLink.module.scss'

import classnames from 'classnames'

import type { SeatbeltLinkTypes } from './Link'

type Props = {
  ReactRouterLink: typeof Link
  to: string
  type: SeatbeltLinkTypes
  linkProps: LinkProps
}

export const InternalRouteLink: React.FC<Props> = ({
  ReactRouterLink,
  to,
  type,
  linkProps,
  children,
}) => {
  const {
    appearance = 'primary',
    includeAnimation = true,
    invertedUnderline = false,
    textStyle,
    className,
  } = linkProps
  const validRRLinkProps = getValidRRLinkProps(linkProps)
  const linkChidrenProps = {
    ...linkProps,
    appearance,
    textStyle,
    includeAnimation,
    invertedUnderline,
  }

  return (
    <ReactRouterLink
      to={to}
      className={classnames(
        linkStyles.link,
        baseLinkStyles.base,
        {
          [navigationLinkStyles.navigationLink]: type === 'NavigationLink',
          [linkStyles.linkPrimary]: appearance === 'primary',
          [linkStyles.linkSecondary]: appearance === 'secondary',
          [baseLinkStyles.baseIncludeAnimation]: includeAnimation,
          [baseLinkStyles.baseIncludeAnimationInverted]: includeAnimation && invertedUnderline,
          [baseLinkStyles.baseIncludeAnimationPrimary]:
            includeAnimation && appearance === 'primary',
        },
        className
      )}
      data-testid={getDataTestId[type]}
      {...validRRLinkProps}
    >
      <LinkChildren {...linkChidrenProps}>{children}</LinkChildren>
    </ReactRouterLink>
  )
}

const getValidRRLinkProps = (linkProps: LinkProps) =>
  Object.keys(linkProps)
    .filter((key) => key === 'id' || key.startsWith('data-'))
    .reduce((obj, key) => {
      obj[key] = linkProps[key]

      return obj
    }, {})

const getDataTestId: Record<SeatbeltLinkTypes, string | undefined> = {
  Link: undefined,
  BaseLink: 'base-link',
  NavigationLink: 'navigation-link',
}
