export const ForgotPasswordEmailField = [
  {
    autoComplete: 'email',
    field: 'email',
    label: 'Your email address',
    maxlength: '64',
    validation: {
      required: true,
      type: 'email',
      pattern:
        // eslint-disable-next-line max-len
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    validationMessage: 'Please enter a valid email address.',
    showRequiredText: false,
    calledFrom: 'forgotPassword',
  },
]

export const Links = {
  forgotPassword: '/forgot-password',
  helpCenter: '/help/faqs-ar5va9s6hi1c',
  join: 'https://join.which.co.uk/',
  returnUrl: '/login?return_url=',
}

export const LoginPageBannerText = {
  authErrorText:
    "Sorry, we don't recognise that combination of email address/username and password.",
  authErrorContentText: `Please try again, or use the <a href="${Links.forgotPassword}" data-which-id="login error link">forgotten password</a> link to reset your password. For further help visit our <a href="${Links.helpCenter}" data-which-id="login error link">help centre</a>.`,
  Success: 'Success - you’ve reset your password. <strong>Please use it to login.</strong>',
}

export const RegistrationFields = [
  {
    autoComplete: 'given-name',
    field: 'firstName',
    label: 'First name',
    validation: { required: true, minLength: 2, maxLength: 16 },
    validationMessage: 'Enter your first name',
    showRequiredText: true,
    calledFrom: 'registration',
  },
  {
    autoComplete: 'family-name',
    field: 'lastName',
    label: 'Last name',
    validation: { required: true, minLength: 2, maxLength: 32 },
    validationMessage: 'Enter your last name',
    showRequiredText: true,
    calledFrom: 'registration',
  },
  {
    autoComplete: 'email',
    field: 'email',
    fieldInfo: 'We will communicate about your membership via email',
    label: 'Email address',
    inputType: 'email',
    validation: {
      required: true,
      type: 'email',
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    },
    validationMessage: 'Enter a valid email address',
    showRequiredText: true,
    calledFrom: 'registration',
  },
]

export const ResetPasswordPageBannerText = {
  linkExpiredErrorText: 'The link to reset your password is either malformed or has expired',
  linkExpiredContentText: 'Please request a new link below.',
}

export const UsernameField = [
  {
    autoComplete: 'username',
    field: 'username',
    label: 'Email or username',
    maxlength: '64',
    validation: { required: true },
    validationMessage: 'Please enter your email address or username.',
    showRequiredText: false,
    calledFrom: 'login',
    trim: true,
  },
]
