/* istanbul ignore file */
/**
 * This file has been excluded from coverage reports as due to it not being possible to
 * use ResizeObserver within Testing library or js-dom it is not possible to get full
 * coverage on this file.
 */
import type { RefObject, SyntheticEvent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { dynamicDatalayerPush } from '@which/shared'

import useResizeObserver from '@react-hook/resize-observer'

import { compareTableColumnWidth } from './constants'

export const useCompareTableScroll = (scrollRef: RefObject<HTMLDivElement>, isHidden: boolean) => {
  const [leftButtonDisabled, setLeftButtonDisabled] = useState<boolean>(true)
  const [rightButtonDisabled, setRightButtonDisabled] = useState<boolean>(false)

  const scrollIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const scrollTable = useCallback(
    (direction: string) => {
      const table = scrollRef?.current as HTMLTableElement
      if (table) {
        const { scrollLeft, scrollWidth, offsetWidth } = table
        let newScrollLeft = scrollLeft
        switch (direction) {
          case 'next':
            newScrollLeft =
              scrollLeft + offsetWidth < scrollWidth - 1
                ? scrollLeft + compareTableColumnWidth
                : scrollWidth
            dynamicDatalayerPush({
              eventCategory: 'Product Compare',
              eventAction: 'Scroll Compare',
              eventLabel: 'Right',
            })
            break
          case 'previous':
            newScrollLeft =
              scrollLeft - compareTableColumnWidth > 0 ? scrollLeft - compareTableColumnWidth : 0
            dynamicDatalayerPush({
              eventCategory: 'Product Compare',
              eventAction: 'Scroll Compare',
              eventLabel: 'Left',
            })
            break
          default:
            break
        }

        table.scrollTo({
          behavior: 'smooth',
          left: newScrollLeft,
        })
      }
    },
    [scrollRef]
  )

  const startScroll = (direction: string) => {
    if (scrollIntervalRef?.current) {
      return
    }
    scrollIntervalRef.current = setInterval(() => scrollTable(direction), 200)
  }

  const stopScroll = () => {
    if (scrollIntervalRef?.current) {
      clearInterval(scrollIntervalRef.current)
      scrollIntervalRef.current = null
    }
  }

  const updateDisabledButtons = useCallback(
    ({ scrollLeft, scrollWidth, offsetWidth }) => {
      /**
       * scrollWidth value reduced by 1 in conditions below to account for rounding
       * of offsetWidth and scrollLeft values after resizing such that their total is
       * actually 1 less than the scrollWidth despite being fully scrolled to the right.
       */
      if (scrollLeft + offsetWidth >= scrollWidth - 1 && !rightButtonDisabled) {
        setRightButtonDisabled(true)
        stopScroll()
      } else if (scrollLeft + offsetWidth < scrollWidth - 1 && rightButtonDisabled) {
        setRightButtonDisabled(false)
      }
      if (scrollLeft === 0 && !leftButtonDisabled) {
        setLeftButtonDisabled(true)
        stopScroll()
      } else if (scrollLeft > 0 && leftButtonDisabled) {
        setLeftButtonDisabled(false)
      }
    },
    [leftButtonDisabled, rightButtonDisabled]
  )

  const handleWrapperScroll = useCallback(
    (event: SyntheticEvent | ResizeObserverEntry) => {
      const { scrollWidth, offsetWidth, scrollLeft } = event.target as HTMLElement
      updateDisabledButtons({ scrollLeft, offsetWidth, scrollWidth })
    },
    [updateDisabledButtons]
  )

  useResizeObserver(scrollRef, handleWrapperScroll)

  useEffect(() => {
    if (scrollRef?.current) {
      const { scrollLeft, offsetWidth, scrollWidth } = scrollRef?.current as HTMLElement
      updateDisabledButtons({ scrollLeft, offsetWidth, scrollWidth, isHidden })
    }
  }, [isHidden, scrollRef, updateDisabledButtons])

  useEffect(() => {
    return stopScroll
  }, [])

  return {
    handleWrapperScroll,
    leftButtonDisabled,
    rightButtonDisabled,
    scrollTable,
    startScroll,
    stopScroll,
  }
}
