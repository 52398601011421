import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Picture, SectionHeader } from '@which/seatbelt'

import type { LatestNewsArticle, Link } from '../../../generated/frontend'
import { LatestNews } from '../LatestNews'
import styles from './CurrentStory.module.scss'

export const CurrentStory: FunctionComponent<Props> = ({
  header,
  articles,
  title,
  navigationLink,
  image,
}) => {
  // TODO: Remove this mapping once the navigationLink type is set
  // to "Link" (instead of custom type inside component)
  // https://whichonline.atlassian.net/browse/WAB-123
  const link = {
    url: navigationLink?.href || '',
    text: navigationLink?.text || '',
  }

  return (
    <Grid data-testid="current-story" className="w-page-wrapper">
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <SectionHeader text={header} className={styles.currentStoryHeader} />
      </GridItem>
      <GridItem span={{ medium: 10, large: 6 }} columnStart={{ medium: 2, large: 2 }}>
        {(articles || navigationLink) && (
          <LatestNews
            articles={articles}
            header={title}
            navigationLink={link}
            className={styles.currentStoryLatestNews}
          />
        )}
      </GridItem>
      <GridItem span={{ medium: 8, large: 4 }} columnStart={{ medium: 3, large: 8 }}>
        <div className={styles.currentStoryPictureFlexWrapper}>
          {image && (
            <div className={styles.currentStoryPictureWrapper}>
              <Picture imageClassName={styles.currentStoryPicture} {...image} />
            </div>
          )}
        </div>
      </GridItem>
    </Grid>
  )
}

type Props = {
  header: string
  articles: LatestNewsArticle[]
  title: string
  navigationLink?: Link
  image?: {
    src: string
  }
}
