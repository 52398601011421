import './styles/index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { getClientSideInstance } from '@which/experiments'
import { SSRProvider as ReactAriaSSRIdProvider } from '@which/seatbelt'
import { ApolloProvider } from '@apollo/client'

import { loadableReady } from '@loadable/component'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { apolloClient } from './shared/apollo-client'
import { BrowserBackStackProvider } from './shared/BrowserBackStackProvider'
import { ExperimentProviderWrapper } from './shared/components/ExperimentProviderWrapper'
import { PreviewIndicator } from './shared/components/PreviewIndicator/PreviewIndicator'
import { ReactRouterProvider } from './shared/contexts/ReactRouterContext'
import { FullUrlProvider } from './shared/FullUrlContext'
import { checkRoute } from './shared/utils/check-route'
import { getQueryString } from './shared/utils/get-query-string'

const previewMode = getQueryString('preview') === 'true'
const client = apolloClient({ target: 'client', preview: previewMode })
const linkContext = {
  checkRoute,
  Link,
}
const getFullUrl = () => window.location.href
const { userAgent } = window.navigator
const allowedHosts = new RegExp(
  `^(localhost|(.*).which.co.uk|${process.env.LOCAL_IP_ADDRESS})$`,
  'gi'
)
allowedHosts.test(window.location.hostname) &&
  loadableReady(async () => {
    const { nonceAttribute } = window

    const gbInstance = await getClientSideInstance({
      jsInjectionNonce: nonceAttribute,
    })
    const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

    renderMethod(
      <React.StrictMode>
        <HelmetProvider context={{}}>
          <ExperimentProviderWrapper experimentObj={gbInstance}>
            <FullUrlProvider value={{ getFullUrl, userAgent }}>
              <ReactRouterProvider value={linkContext}>
                <ApolloProvider client={client}>
                  <Router>
                    <BrowserBackStackProvider>
                      <ReactAriaSSRIdProvider>
                        <App />
                      </ReactAriaSSRIdProvider>
                    </BrowserBackStackProvider>
                  </Router>
                </ApolloProvider>
              </ReactRouterProvider>
            </FullUrlProvider>
            <PreviewIndicator preview={previewMode} />
          </ExperimentProviderWrapper>
        </HelmetProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  })

/*
 * Ensure all service workers are unregistered
 */
serviceWorker.unregister()
