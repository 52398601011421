import type { FunctionComponent } from 'react'
import React, { createElement } from 'react'

import classnames from 'classnames'

import styles from '../ComparisonTable.module.scss'

export const FillerCells: FunctionComponent<Props> = ({ className, count, cellType = 'td' }) => {
  const fillerCells: JSX.Element[] = []
  for (let i = 0; i < count; i++) {
    fillerCells.push(
      createElement(cellType, {
        key: `filter-cell-${i}`,
        className: classnames(className, styles.emptyCell, styles.tableData),
      })
    )
  }
  return <>{fillerCells}</>
}

//////IMPLEMENTATION//////

type Props = {
  count: number
  className?: string
  cellType?: string
}
