import type { FunctionComponent } from 'react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormField, InputV2 } from '@which/seatbelt'
import type { InputTypes } from '@which/seatbelt/build/src/components/Seatbelt2/BuildingBlocks/Forms/Input/Input'
import { dynamicDatalayerPush } from '@which/shared'

import style from '../../registration-page/RegistrationPage.module.scss'

export const FormFields: FunctionComponent<Props> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const callDataLayerForFormField = (field = '', calledFrom = '') => {
    dynamicDatalayerPush({
      eventCategory: 'Form Usage',
      eventAction: `/${calledFrom}`,
      eventLabel: field,
    })
  }

  const renderField = ({
    autoComplete,
    field,
    fieldInfo = '',
    label,
    maxlength = '',
    inputType = 'text' as InputTypes,
    required = true,
    tooltip = '',
    trim = false,
    validation,
    validationMessage,
    showRequiredText,
    calledFrom,
  }) => {
    return (
      <Controller
        control={control}
        name={field}
        defaultValue=""
        rules={validation}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            label={label}
            errorMsg={errors[field] && validationMessage}
            className={style.registrationPageFormField}
            tooltip={tooltip}
            required={required}
            labelFor={field}
            fieldInfo={fieldInfo}
            showRequiredText={showRequiredText}
          >
            <InputV2
              autoComplete={autoComplete}
              hasErrors={!!errors[field]}
              name={field}
              type={inputType}
              maxLength={maxlength}
              onFocus={() =>
                calledFrom !== 'registration' ? null : callDataLayerForFormField(field, calledFrom)
              }
              value={value}
              onChange={(e) => onChange(trim ? e.target.value.trim() : e.target.value)}
              onBlur={onBlur}
            />
          </FormField>
        )}
      />
    )
  }

  return (
    <>
      {props.fields.map((fieldList, key) => (
        <React.Fragment key={key}>{renderField(fieldList)}</React.Fragment>
      ))}
    </>
  )
}

///////// IMPLEMENTATION /////////
type Fields = {
  autoComplete: string
  calledFrom: string
  field: string
  label: string
  showRequiredText: boolean
  validation: {
    required: boolean
    pattern?: RegExp
    type?: string
  }
  validationMessage: string
  fieldInfo?: string
  inputType?: InputTypes
  maxlength?: string
  trim?: boolean
}

type Props = {
  fields: Fields[]
}
