import newrelic from 'newrelic'

import styles from './components/PasswordField/PasswordField.module.scss'

export const setNewRelicTransaction = (pagePath: string) => {
  if (typeof newrelic !== 'undefined') {
    newrelic.setTransactionName(pagePath)
  }
}

export const getIcon = ({ className }) => {
  if (className) {
    if (className.includes('Neutral')) {
      return ''
    } else if (className.includes('InValid')) {
      return 'cross'
    } else {
      return 'tick'
    }
  }
}

export const checkValidationConditions = (
  validated: boolean,
  errorMsg?: string,
  message?: string
) => {
  if (validated) {
    return { className: styles.passwordFieldListValid, message: '' }
  } else if (errorMsg) {
    return { className: styles.passwordFieldListInValid, message: message }
  } else {
    return { className: styles.passwordFieldListNeutral, message: message }
  }
}

export const findWhichDomain = (url) => {
  if (url) {
    const { hostname } = new URL(url)
    if (hostname.includes('which.co.uk')) {
      return url
    }
  }
  return 'https://www.which.co.uk/'
}

export const findBlaizeSessionAvailable = (name) => {
  const cookieName = `${name}=`
  const splitCookies = document.cookie.split(';')

  for (let cookie of splitCookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }
  return null
}

export const accountPagesDataLayer = (dlObj) => {
  const updateDLObj = Object.fromEntries(Object.entries(dlObj).filter(([, v]) => v != null))

  return `
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(
    ${JSON.stringify(updateDLObj)}
  )`
}

export const isReferrerDomain = (referrer): boolean => {
  const referrerDomains = new RegExp(/^(.*)?which.co.uk/gi)
  const referrerDomainsExceptions = new RegExp(/(.*)?(\bjoin|\btry).which.co.uk/gi)
  if (referrerDomainsExceptions.test(referrer)) {
    return false
  }
  return referrerDomains.test(referrer)
}

export const getIsValidReferrer = (referrer: unknown): boolean => {
  return (
    isValidUrl(referrer) &&
    isReferrerDomain(referrer) &&
    !referrer.includes('forgot-password') &&
    !referrer.includes('reset-password') &&
    !referrer.includes('login') &&
    !referrer.includes('logout')
  )
}

const isValidUrl = (possibleUrl: unknown): possibleUrl is string => {
  try {
    return typeof possibleUrl === 'string' && Boolean(new URL(possibleUrl))
  } catch {
    return false
  }
}
