import type { Comparison } from '../../../shared/types/ReviewsCompareTray'

export const getComparisonLimitReacted = ({ compareTrayItems = [] }: Props1) => {
  return !!compareTrayItems && compareTrayItems.length >= 4
}

export const getIsSelectedForComparison = ({ compareTrayItems = [], slug }: Props2) => {
  return !!compareTrayItems?.find((product) => product.slug === slug)
}

type Props1 = {
  compareTrayItems: Comparison[] | undefined
}

type Props2 = Props1 & { slug: string }
