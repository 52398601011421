import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, CardRow, TypographyV2 as Typography } from '@which/seatbelt'

import type { LatestNews as LatestNewsType } from '../../../../generated/frontend'

export const LatestNews: FunctionComponent<Props> = ({ news, whichID, ...rest }) => {
  if (!news || !news?.articles?.length) {
    return null
  }

  return (
    <div>
      <CardRow
        title={
          <>
            <Typography textStyle="sb-text-heading-overline">Latest News In</Typography>
            <span style={{ textTransform: 'capitalize' }}>Which? {news.name}</span>
          </>
        }
        text="View all news"
        link={{ text: 'View all news', href: 'https://www.which.co.uk/news' }}
        whichID={whichID}
        {...rest}
      >
        {news.articles.map(({ title, dateInfo, href, imageWithSources }) => (
          <ArticleCard
            key={`article_${title}`}
            image={imageWithSources?.src}
            imageSources={imageWithSources?.sources}
            imageAlt={imageWithSources?.alt ?? title}
            date={dateInfo?.rendered}
            href={href}
            display={'scroll-row'}
            title={title}
          />
        ))}
      </CardRow>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  news?: LatestNewsType
  whichID?: string
} & Partial<Parameters<typeof CardRow>[0]>
