import type { ImageWithSources } from '@which/shared'

export const defaultImage: ImageWithSources = {
  src: 'https://media.product.which.co.uk/prod/images/original/26ae8dd920e7-bband-offer-header-526-two-to-one.png',
  alt: 'Compare broadband deals',
  sources: [
    {
      srcset: `https://media.product.which.co.uk/prod/images/ar_2to1_320x160/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 320w,
        https://media.product.which.co.uk/prod/images/ar_2to1_500x250/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 500w,
        https://media.product.which.co.uk/prod/images/ar_2to1_700x350/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 700w,
        https://media.product.which.co.uk/prod/images/ar_2to1_900x450/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 900w,
        https://media.product.which.co.uk/prod/images/ar_2to1_1200x600/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 1200w,
        https://media.product.which.co.uk/prod/images/ar_2to1_1500x750/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 1500w, 
        https://media.product.which.co.uk/prod/images/ar_2to1_1800x900/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 1800w,
        https://media.product.which.co.uk/prod/images/ar_2to1_2100x1050/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 2100w,
        https://media.product.which.co.uk/prod/images/ar_2to1_2400x1200/26ae8dd920e7-bband-offer-header-526-two-to-one.webp 2400w`,
      sizes:
        '(min-width: 1440px) 370px, (min-width: 1024px) 25vw, (min-width: 901px) 61vw, (min-width: 768px) 75vw, 90vw',
      type: '',
      media: '',
    },
  ],
}

export const offerImage: ImageWithSources = {
  src: 'https://media.product.which.co.uk/prod/images/original/3705a716c306-broadband-promo-image.png',
  alt: 'Compare broadband deals',
  sources: [
    {
      srcset: `https://media.product.which.co.uk/prod/images/ar_2to1_320x160/3705a716c306-broadband-promo-image.webp 320w,
      https://media.product.which.co.uk/prod/images/ar_2to1_500x250/3705a716c306-broadband-promo-image.webp 500w,
      https://media.product.which.co.uk/prod/images/ar_2to1_700x350/3705a716c306-broadband-promo-image.webp 700w,
      https://media.product.which.co.uk/prod/images/ar_2to1_900x450/3705a716c306-broadband-promo-image.webp 900w,
      https://media.product.which.co.uk/prod/images/ar_2to1_1200x600/3705a716c306-broadband-promo-image.webp 1200w,
      https://media.product.which.co.uk/prod/images/ar_2to1_1500x750/3705a716c306-broadband-promo-image.webp 1500w, 
      https://media.product.which.co.uk/prod/images/ar_2to1_1800x900/3705a716c306-broadband-promo-image.webp 1800w,
      https://media.product.which.co.uk/prod/images/ar_2to1_2100x1050/3705a716c306-broadband-promo-image.webp 2100w,
      https://media.product.which.co.uk/prod/images/ar_2to1_2400x1200/3705a716c306-broadband-promo-image.webp 2400w`,
      sizes:
        '(min-width: 1440px) 370px, (min-width: 1024px) 25vw, (min-width: 901px) 61vw, (min-width: 768px) 75vw, 90vw',
    },
  ],
}
