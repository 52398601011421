import type { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import type { ProductHubSlug } from '@which/shared'

import loadable from '@loadable/component'

import type { Breadcrumb, ProductHubPageQuery } from '../../generated/frontend'
import type { PageParams } from '../../routes'
import { PageError } from '../../shared'
import type { AdditionalContentProps } from './components/AdditionalContent/AdditionalContent'
import type { ProductHubAdviceProps } from './components/ProductHubAdvice/ProductHubAdvice'
import type { ProductHubBrandLinksProps } from './components/ProductHubBrandLinks/ProductHubBrandLinks'
import type { ProductHubIntroProps } from './components/ProductHubIntro/ProductHubIntro'
import type { ProductHubJumpLinksProps } from './components/ProductHubJumpLinks/ProductHubJumpLinks'
import { ProductHubPage } from './components/ProductHubPage'
import type { ProductTypesDataProps } from './components/ProductHubProductTypes/ProductHubProductTypes'
import type { ProductHubReviewsHardcodedProps } from './components/ProductHubReviews/ProductHubReviews'
import type { ProductHubRightsProps } from './components/ProductHubRights/ProductHubRights'
import type { RelatedProducts } from './components/RelatedProducts'
import type { ProductHubBrandLinksV2Props } from './components/v2/components/ProductHubBrandLinksV2/ProductHubBrandLinksV2'
import type { ProductHubCTACardsProps } from './components/v2/components/ProductHubCTACards/ProductHubCTACards'
import type { ProductHubGridLayoutProps } from './components/v2/components/ProductHubGridLayout/ProductHubGridLayout'
import type { ProductHubLinkSectionProps } from './components/v2/components/ProductHubLinkSection/ProductHubLinkSection'
import { ProductHubPageV2 } from './components/v2/ProductHubPageV2'
import { useProductHubPageMetaTags } from './hooks/useProductHubPageMetaTags'

/* TODO Delete once moved to content-editable Product Hubs
This file ONLY drives hardcoded pages */
const LoadStaticData = loadable.lib<never, ProductHubStaticData>(
  () => import('./constants/product-hub'),
  {
    ssr: true,
  }
)

type ProductHubStaticData = {
  productHubData: ProductHubHardcodedData
  productHubDataLayer: Record<string, Record<string, string>>
}

type HardcodedProductHubProps = {
  isLoggedIn: ProductHubPageQuery['productHubPage']['isLoggedIn']
  products: ProductHubPageQuery['productHubPage']['products']
  latestNews: ProductHubPageQuery['productHubPage']['latestNews']
  transformTypeDecision: ProductHubPageQuery['productHubPage']['transformTypeDecision']
  showResubBanner: ProductHubPageQuery['productHubPage']['showResubBanner']
}

const ProductHub: FunctionComponent<HardcodedProductHubProps> = (data) => {
  const { getMetaTags } = useProductHubPageMetaTags()
  const { productSlug } = useParams<PageParams>()

  return (
    <LoadStaticData>
      {({ productHubData, productHubDataLayer }) => {
        const hardcodedData = productHubData[productSlug]

        if (!hardcodedData) {
          return <PageError pageName="Product Hub" />
        }

        const { isLoggedIn } = data

        const productHubDataLayerData = productHubDataLayer[productSlug]

        return (
          <>
            <Helmet>
              {getMetaTags({
                meta: {
                  dataLayer: [
                    {
                      loginStatus: isLoggedIn ? 'loggedIn' : 'loggedOut',
                      paidAccess: isLoggedIn ? 'paid' : 'free',
                      sub_vertical: productHubDataLayerData.sub_vertical,
                      category: productHubDataLayerData.category,
                      category_id: productHubDataLayerData.category_id,
                    },
                  ],
                  desc: productHubDataLayerData.desc,
                  facebookTitle: productHubDataLayerData.title,
                  facebookDesc: productHubDataLayerData.desc,
                  facebookImage: hardcodedData.intro.image.src,
                  fbAppId: '',
                  title: productHubDataLayerData.title,
                  pageType: 'product-hub',
                  taxonomyHierarchy: {
                    vertical: { slug: productHubDataLayerData.vertical },
                    subVertical: { slug: productHubDataLayerData.sub_vertical },
                    category: { slug: productHubDataLayerData.category },
                    superCategory: null,
                  },
                  twitterTitle: productHubDataLayerData.title,
                  twitterDesc: productHubDataLayerData.desc,
                  twitterImage: hardcodedData.intro.image.src,
                  canonical: `https://www.which.co.uk/l/${productHubDataLayerData.sub_vertical}/${productSlug}`,
                  __typename: 'Meta',
                },
                imageUrl: hardcodedData.intro.image.src,
                imageAlt: productHubDataLayerData.title,
              })}
            </Helmet>
            {productSlug === 'televisions' ? (
              <ProductHubPageV2 {...{ ...hardcodedData, ...data }} />
            ) : (
              <ProductHubPage {...{ ...hardcodedData, ...data }} />
            )}
          </>
        )
      }}
    </LoadStaticData>
  )
}

export default ProductHub

export type ProductHubHardcodedData = Record<ProductHubSlug, ProductHubHardcodedProps>

export type ProductHubHardcodedProps = {
  intro: ProductHubIntroProps
  jumpLinks: ProductHubJumpLinksProps
  breadcrumb: Breadcrumb
  productReviews: ProductHubReviewsHardcodedProps
  brandLinks?: ProductHubBrandLinksProps
  brandLinksV2?: ProductHubBrandLinksV2Props & ProductHubGridLayoutProps
  relatedProducts: RelatedProducts
  advice: ProductHubAdviceProps & ProductHubGridLayoutProps
  additionalContent?: AdditionalContentProps
  rights: ProductHubRightsProps & ProductHubGridLayoutProps
  description: AdditionalContentProps
  productTypesData?: ProductTypesDataProps
  ctaSections?: ProductHubCTACardsProps[]
  otherBrandLinks?: ProductHubLinkSectionProps[]
}
