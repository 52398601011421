import React from 'react'
import type { NewsletterSource } from '@which/glide-ts-types'

import { Link } from '../components/Link'

const defaultSuccessText = (
  <p>
    You will receive our newsletter every week. Unsubscribe whenever you want. Your data will be
    processed in accordance with our{' '}
    <Link href="https://www.which.co.uk/help/which-privacy-notice-aGL107i27ybI" target="_blank">
      Privacy policy
    </Link>
  </p>
)

export const sourceMap: SourceType = {
  money: {
    header: 'Be more money savvy',
    description:
      "Get a firmer grip on your finances with the expert tips in our Money newsletter – it's free weekly.",
    buttonText: 'Get the newsletter',
    unsubscribeText: (
      <p>
        This newsletter delivers free money-related content, along with other information about{' '}
        <Link
          href="https://www.which.co.uk/help/terms-and-conditions/1948/general-terms#headline_5"
          target="_blank"
          textStyle="sb-text-interface-body-x-small-regular"
        >
          Which? Group
        </Link>{' '}
        products and services. Unsubscribe whenever you want. Your data will be processed in
        accordance with our{' '}
        <Link
          href="https://www.which.co.uk/help/which-privacy-notice-aGL107i27ybI"
          target="_blank"
          textStyle="sb-text-interface-body-x-small-regular"
        >
          Privacy policy
        </Link>
      </p>
    ),
    successHeader: 'You’ve successfully signed up to Which? Money Weekly',
    successText: defaultSuccessText,
    withAllFields: true,
  },
  sustainability: {
    header: 'Live more sustainably',
    description:
      'Our free monthly Sustainability newsletter can help you make everyday changes for you and the planet.',
    buttonText: 'Sign up',
    successHeader: 'You’ve successfully signed up to Which? Sustainability Weekly',
    successText: defaultSuccessText,
    withAllFields: true,
  },
  weekly_scoop: {
    header: 'Be a smarter consumer',
    description: 'Use our latest tips to choose well, buy better and save money – free weekly',
    buttonText: 'Get the newsletter',
    successHeader: 'You’ve successfully signed up to Which? Weekly scoop',
    successText: defaultSuccessText,
    withAllFields: true,
  },
}

type SourceType = Record<
  NewsletterSource,
  {
    header: string
    description: string
    buttonText?: string
    unsubscribeText?: JSX.Element
    successHeader: string
    successText: JSX.Element
    withAllFields?: boolean
  }
>
