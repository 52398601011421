import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { providerScorePaths } from '../../utils/checkUrlExistsInList/checkUrlExistsInList'
import { CsvTableUnlocked } from './CsvTableUnlocked'
import { CsvTableLocked } from './CsvTableLocked'
import { CsvTableProviderScoreVariation } from './CsvTableProviderScoreVariation'

export const CsvTable: FunctionComponent<Props> = (props) => {
  const { pathname } = useLocation()
  const useProviderScoreVariation = providerScorePaths.some((path) => pathname.includes(path))

  if (useProviderScoreVariation) {
    return <CsvTableProviderScoreVariation {...props} />
  }

  const { testVariant: lockedVariant } = props
  const isLocked = !!lockedVariant
  const CsvTableComponent = isLocked ? CsvTableLocked : CsvTableUnlocked

  return <CsvTableComponent {...props} />
}

///////// IMPLEMENTATION /////////

export type ArticleElement = {
  sortableValue?: number | string
  tagName: string
  props?: Record<string, unknown>
  innerText?: string
  children?: ArticleElement[]
}

export type TableBodyContent = string | ArticleElement

export type SortType = 'text' | 'stars' | 'percentage' | 'rich_cell'

export type Props = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
  renderArticleElements: (x: ArticleElement[]) => ReactElement
  enableSearch?: boolean
  id?: string
  notes?: string
  testVariant?: 'scoresLocked' | 'providersLocked'
  restrictions?: ('restrict' | '-')[]
  endnoteColumnHeader?: string
  endnoteColumnBody?: string
}
