import React from 'react'

import type { PageInfo } from './types'

export const getDFPScripts = (dataLayer: DataLayerLoggedInStatus[], pageInfo: PageInfo) => {
  const login = dataLayer.find((item) => item?.loginStatus)

  return [
    <script key="google-tag">{`window.googletag = window.googletag || { cmd: [] };
      window.dfp = {
        vertical: '${pageInfo?.vertical ?? ''}',
        sub_vertical: '${pageInfo?.sub_vertical ?? ''}',
        category: '${pageInfo?.category ?? ''}',
        tags: ${JSON.stringify(pageInfo?.tags ?? [])},
        keywords: ${JSON.stringify(pageInfo?.keywords ?? [])},
        login_status: '${login?.loginStatus ?? 'loggedOut'}',
        content_id: '${pageInfo?.content_id ?? ''}',
        content_type: '${pageInfo?.content_type ?? ''}',
        page_type: '${pageInfo?.pageType ?? ''}',
      };`}</script>,
  ]
}

///////// IMPLEMENTATION /////////

type DataLayerLoggedInStatus = {
  loginStatus?: string
}
