import type { FunctionComponent } from 'react'
import React, { useMemo } from 'react'
import type { ImageSource, LinksListExpandable } from '@which/seatbelt'
import { ContentCardV2, useId } from '@which/seatbelt'
import { isExternalLink } from '@which/shared'

import type { Link } from '../../../generated/frontend'
import styles from './ToolCta.module.scss'

export const ToolCTA: FunctionComponent<Props> = ({
  button,
  description,
  image,
  title,
  widgetImageAspectRatio,
  linksListExpandableData,
  captionText,
}) => {
  const uniqueId = useId()
  const contentHtmlId = useMemo(() => `tool-cta-links-list-${uniqueId}`, [uniqueId])
  const buttonProps: Link = { text: button?.text ?? '', href: button?.href ?? '' }
  const buttonLinkAdditionalProps = isExternalLink(buttonProps.href)
    ? { target: '_blank', rel: 'nofollow noopener noreferrer' }
    : {}

  const imageProps = {
    aspectRatioMain: getImageAspectRatio(widgetImageAspectRatio),
    imageAlt: image?.alt || '',
    imageSources: image?.sizes,
    lazyLoad: false,
    str: image?.src || '',
  }

  const secondaryLinksProps = {
    ariaLabel: linksListExpandableData?.button.ariaLabel || '',
    buttonText: linksListExpandableData?.button.text || '',
    contentHtmlId,
    links: linksListExpandableData?.items || [],
  }

  return (
    <div data-testid="tool-cta" className={styles.toolCTA}>
      <ContentCardV2
        arrangement={'horizontal'}
        buttonLinkText={buttonProps.text}
        buttonLinkAdditionalProps={buttonLinkAdditionalProps}
        description={description}
        imageRight={true}
        imgObj={imageProps}
        primaryLink={buttonProps.href}
        secondaryLinks={secondaryLinksProps}
        title={title}
        legalText={captionText}
        titleTag="h3"
      />
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getImageAspectRatio = (widgetImageAspectRatio: WidgetImageAspectRatio) =>
  ({
    ar_1to1_: 'one-to-one' as const,
    ar_2to1_: 'two-to-one' as const,
  })[widgetImageAspectRatio]

type WidgetImageAspectRatio = 'ar_1to1_' | 'ar_2to1_'

export type Props = {
  button: Link
  description?: string
  image?: {
    alt: string
    sizes?: ImageSource[]
    src: string
  }
  linksListExpandableData?: Omit<Parameters<typeof LinksListExpandable>[0], 'contentHtmlId'>
  title: string
  widgetImageAspectRatio: WidgetImageAspectRatio
  captionText?: string
}
