import React, { useCallback, useState } from 'react'
import { Table as t } from '@which/seatbelt'

import type { ArticleElement, Props, SortType, TableBodyContent } from './CsvTable'
import { useTableSearch } from '../../hooks/useTableSearch'
import { ShowMore } from '../ShowMore'
import type { ShowMoreContentRenderer } from '../ShowMore/ShowMore'
import styles from './CsvTable.module.scss'
import { extendElementProps, getCellAlignment, getInitialTableData, isValidRowItem } from './utils'

export const CsvTableUnlocked: React.FC<Props> = (tableData) => {
  const defaultTableData = getInitialTableData(tableData)
  const [sortableTableData, setSortedBodyTable] = useState<CsvTableState>(defaultTableData)
  const [renderFullContent, setRenderFullContent] = useState<boolean>(false)
  const { headings, sortedTableBody } = sortableTableData
  const { TableSearch } = useTableSearch(tableData?.id, tableData.enableSearch)
  const { endnoteColumnHeader, endnoteColumnBody } = tableData
  const renderEndnoteColumn =
    typeof endnoteColumnHeader === 'string' &&
    typeof endnoteColumnBody === 'string' &&
    endnoteColumnBody.length > 0

  const onSort = useCallback(
    (columnIndex: number, currentSortState: SortOption) => {
      const sortStates: SortOption[] = ['descending', 'ascending']
      const currentSortStateIndex = sortStates.indexOf(currentSortState)
      const newSortState = sortStates[(currentSortStateIndex + 1) % 2]
      const targetHeading = headings[columnIndex]
      const newState = {
        ...sortableTableData,
        headings: {
          ...defaultTableData.headings,
          [columnIndex]: {
            ...targetHeading,
            sortDirection: newSortState,
          },
        },
        sortedTableBody: sortTable({
          columnIndex,
          tableBody: sortedTableBody,
          sortType: targetHeading.columnDataType as SortType,
          sortDirection: newSortState,
        }),
      }

      setSortedBodyTable(newState)
    },
    [defaultTableData.headings, headings, sortableTableData, sortedTableBody]
  )

  const tableRenderer = useCallback<ShowMoreContentRenderer>(
    (children) => {
      const bodyContent = children as TableBodyContent[][]

      const optionalEndnoteBody = renderEndnoteColumn ? (
        <t.Cell rowSpan={bodyContent.length + 1} align="centre">
          <div dangerouslySetInnerHTML={{ __html: endnoteColumnBody }}></div>
        </t.Cell>
      ) : null

      return (
        <t.Table tableClassName="scrollable" tableId={tableData.id}>
          <t.Head>
            <t.Row>
              {Object.values(headings).map((heading: Heading, columnIndex: number) => (
                <t.Header
                  key={`header_${columnIndex}`}
                  sortDirection={heading.sortDirection}
                  onSort={() => {
                    setRenderFullContent(true)
                    onSort(columnIndex, heading.sortDirection)
                  }}
                >
                  {heading.value}
                </t.Header>
              ))}
              {renderEndnoteColumn && (
                <t.Header nonSortStyles={{ minWidth: '165px' }}>{endnoteColumnHeader}</t.Header>
              )}
            </t.Row>
          </t.Head>

          <t.Body>
            {bodyContent.map((row, rowIndex) => {
              const [firstItem] = row

              if (!isValidRowItem(firstItem)) {
                return null
              }

              return (
                <t.Row key={`row_${rowIndex}`}>
                  {row.map((bodyRowData: string | ArticleElement, cellIndex) => (
                    <t.Cell
                      key={`cell_${rowIndex}_${cellIndex}`}
                      align={getCellAlignment(bodyRowData)}
                    >
                      {typeof bodyRowData === 'string'
                        ? bodyRowData
                        : tableData.renderArticleElements(
                            extendElementProps({
                              bodyContent,
                              rowIndex,
                              bodyRowData,
                              cellIndex,
                              row,
                            })
                          )}
                    </t.Cell>
                  ))}
                  {rowIndex === 0 && optionalEndnoteBody}
                </t.Row>
              )
            })}
          </t.Body>
        </t.Table>
      )
    },
    [headings, onSort, tableData]
  )

  return (
    <div className={styles.csvTable}>
      {tableData.enableSearch && (
        <div className={styles.csvTableSearchWrapper}>
          <TableSearch
            tableId={tableData.id}
            className={styles.csvTableSearchInput}
            setRenderFullContent={setRenderFullContent}
            renderFullContent={renderFullContent}
          />
        </div>
      )}

      <ShowMore
        className={styles.csvTableShowMoreWrapper}
        contentRenderer={tableRenderer}
        buttonLabel="Show all rows"
        renderFullContent={renderFullContent}
      >
        {sortedTableBody}
      </ShowMore>
      {tableData?.notes && (
        <div
          data-testid="csv-table-note"
          className={styles.tableNotes}
          dangerouslySetInnerHTML={{ __html: tableData?.notes ?? '' }}
        />
      )}
    </div>
  )
}

export type SortOption = 'default' | 'ascending' | 'descending'

export type TableData = {
  headings: string[]
  columnDataTypes: SortType[]
  tableBody: TableBodyContent[][]
}

export type Heading = {
  value: string
  columnDataType: SortType
  sortDirection: SortOption
}

export type CsvTableState = {
  headings: {
    [x: number]: Heading
  }
  sortedTableBody: TableBodyContent[][]
}

export type ExtendElementPropsArgs = {
  bodyRowData: ArticleElement
  rowIndex: number
  bodyContent: TableBodyContent[][]
  cellIndex: number
  row: TableBodyContent[]
}

type SortTableParams = {
  columnIndex: number
  tableBody: TableBodyContent[][]
  sortType: SortType
  sortDirection: SortOption
}

const sortTable = ({ columnIndex, tableBody, sortType, sortDirection }: SortTableParams) => {
  const sortedTableBody = [...tableBody]
  const sortValue = sortType.toLowerCase()

  switch (sortValue) {
    case 'text': {
      const collator = Intl.Collator('en', { numeric: true, sensitivity: 'base' })

      return sortedTableBody.sort((a, b) => {
        const leftValue = getSortableValue(a[columnIndex], sortType)
        const rightValue = getSortableValue(b[columnIndex], sortType)

        return sortDirection === 'ascending'
          ? collator.compare(leftValue, rightValue)
          : collator.compare(rightValue, leftValue)
      })
    }
    case 'rich_cell': {
      return sortedTableBody.sort((a, b) => {
        const leftValue = getSortableValue(a[columnIndex], sortType)
        const rightValue = getSortableValue(b[columnIndex], sortType)

        if (leftValue === rightValue) {
          return 0
        }

        if (sortDirection === 'ascending') {
          return leftValue > rightValue ? 1 : -1
        }

        if (sortDirection === 'descending') {
          return leftValue < rightValue ? 1 : -1
        }

        return -1
      })
    }
    default: {
      return sortDirection === 'ascending'
        ? sortedTableBody.sort(
            (a, b) =>
              getSortableValue(a[columnIndex], sortType) -
              getSortableValue(b[columnIndex], sortType)
          )
        : sortedTableBody.sort(
            (a, b) =>
              getSortableValue(b[columnIndex], sortType) -
              getSortableValue(a[columnIndex], sortType)
          )
    }
  }
}

const getSortableValue = (value: TableBodyContent, sortType: SortType) => {
  if (value && (value['sortableValue'] || value['sortableValue'] === '')) {
    if (sortType === 'stars' && typeof value['sortableValue'] !== 'number') {
      return 0
    }

    return value['sortableValue']
  }

  switch (sortType) {
    case 'percentage':
      return value ? parseFloat(value as string) : ''
    case 'stars':
      return value ? (value as string).length : 0
    case 'text':
      return value ? (value as string).toLowerCase() : ''
    default:
      return value
  }
}
