/* istanbul ignore file */
/**
 * This file has been excluded from coverage reports as due to it not being possible to
 * use ResizeObserver within Testing library or js-dom it is not possible to get full
 * coverage on this file.
 */
import { useCallback, useLayoutEffect, useState } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

export const useLayoutScroll = (scrollRefEl) => {
  const [scrollCoordinates, setScrollCoordinates] = useState<ScrollCoordinates>({
    x: 0,
    y: 0,
  })

  const [isScrollable, setIsScrollable] = useState<boolean>(false)

  const handler = useCallback(() => {
    const scrollRefCurrent = scrollRefEl
    let ticking = false

    if (!ticking) {
      window.requestAnimationFrame(function () {
        setScrollCoordinates({
          x: scrollRefCurrent.scrollLeft,
          y: scrollRefCurrent.scrollTop,
        })
        ticking = false
      })
      ticking = true
    }
  }, [scrollRefEl])

  const updateIsScrollable = useCallback(
    (event: ResizeObserverEntry) => {
      const { clientWidth, scrollWidth } = event?.target as HTMLElement
      /**
       * scrollWidth value reduced by 1 in conditions below to account for rounding
       * of scrollwidth and clientWidth values after resizing such scrollWidth is 1 higher
       * than clientWidth when the element is fully scrolled to the right.
       */
      if (scrollWidth - 1 <= clientWidth && isScrollable) {
        setIsScrollable(false)
      } else if (scrollWidth - 1 > clientWidth && !isScrollable) {
        setIsScrollable(true)
      }
    },
    [isScrollable]
  )

  useResizeObserver(scrollRefEl, updateIsScrollable)

  useLayoutEffect(() => {
    if (scrollRefEl) {
      scrollRefEl.addEventListener('scroll', handler, { passive: true })
      setIsScrollable(scrollRefEl.scrollWidth > scrollRefEl.clientWidth)

      return () => {
        scrollRefEl.removeEventListener('scroll', handler)
      }
    }
  }, [scrollRefEl, handler])

  return { x: scrollCoordinates.x, isScrollable }
}

export type ScrollCoordinates = {
  x: number
  y: number
}
