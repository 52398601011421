import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import type { ValidTypographyTags } from '@which/seatbelt/src/types'

export const ArticleTypography: FunctionComponent<Props> = ({ tag, children, ...rest }) => (
  <Typography tag={tag} className={`${tag}-article`} textStyle={setTextStyle(tag)} {...rest}>
    {children}
  </Typography>
)

///////// IMPLEMENTATION /////////

type Props = {
  tag: ValidTypographyTags
}

const setTextStyle = (tag: Props['tag']) => {
  switch (tag) {
    case 'h1':
      return 'sb-text-heading-page-title'
    case 'h2':
      return 'sb-text-heading-large'
    case 'h3':
      return 'sb-text-heading-medium'
    case 'h4':
    case 'h5':
    case 'h6':
      return 'sb-text-heading-small'
    case 'p':
    case 'span':
    case 'li':
      return 'sb-text-body-default-regular'
    default:
      return 'sb-text-body-default-regular'
  }
}
