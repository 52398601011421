import type { FunctionComponent } from 'react'
import React from 'react'
import type { AspectRatio } from '@which/seatbelt'
import { ButtonLink, PageTitle, SectionHeader, SmallHorizontalArticleCard } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import type { ButtonAppearance } from '@which/seatbelt-shared'

import classnames from 'classnames'

import type { LatestNewsArticle } from '../../../generated/frontend'
import { Link } from '../Link'
import styles from './LatestNews.module.scss'

export const LatestNews: FunctionComponent<Props> = ({
  name,
  articles,
  className,
  header,
  navigationLink,
  aspectRatioMain,
  lazyLoad = true,
}) => {
  if (!articles?.length) {
    return null
  }

  const mappedArticles = articles.map((article) => ({
    ...article,
    ...(article.imageWithSources && {
      imageWithSources: {
        ...article.imageWithSources,
        alt: article.imageWithSources.alt ?? '',
        ...(aspectRatioMain && { aspectRatioMain }),
        lazyLoad,
      },
    }),
  }))

  return (
    <div
      data-testid="latest-news-wrapper"
      className={classnames(styles.latestNews, className)}
      id="jumplink-latest-news"
    >
      {name && <SectionHeader className={styles.latestNewsName} text={name} />}
      {header && <PageTitle pageTitle={header} pageTitleTag="h1" />}
      <ol aria-label={name} className={styles.latestNewsList}>
        {mappedArticles.map(({
          title,
          href,
          dateInfo,
          imageWithSources,
          'data-which-id': dataWhichId,
          'data-section': dataSection,
          'data-card-name': dataCardName,
          'data-index': dataIndex
        }) => (
          <li className={styles.latestNewsListItem} key={href}>
            <SmallHorizontalArticleCard
              data-which-id={dataWhichId}
              data-section={dataSection}
              data-card-name={dataCardName}
              data-index={dataIndex}
              title={title}
              href={href}
              image={imageWithSources}
              date={dateInfo?.rendered}
              dateTime={dateInfo?.iso}
            />
          </li>
        ))}
      </ol>
      {navigationLink && <NavigationLink {...navigationLink} />}
    </div>
  )
}

///////// IMPLEMENTATION /////////

const NavigationLink: FunctionComponent<NavigationLinkProps> = ({
  url,
  text,
  useButtonLink,
  ...rest
}) =>
  useButtonLink ? (
    <ButtonLink href={url} className={styles.latestNewsAllNews} {...rest}>
      <ChevronRightIcon />
      {text}
    </ButtonLink>
  ) : (
    <Link
      href={url}
      includeIcon={true}
      className={classnames(styles.latestNewsAllNews, styles.latestNewsAllNewsLink)}
      {...rest}
    >
      {text}
    </Link>
  )

export type Props = {
  name?: string
  articles: LatestNewsArticleItem[]
  className?: string
  header?: string
  navigationLink?: NavigationLinkProps
  aspectRatioMain?: AspectRatio
  lazyLoad?: boolean
}

export type LatestNewsArticleItem = Omit<LatestNewsArticle, 'authors'> & AdditionalDataAttrs

type NavigationLinkProps = {
  text: string
  url: string
  useButtonLink?: boolean
  appearance?: ButtonAppearance
} & AdditionalDataAttrs

type AdditionalDataAttrs = {
  'data-which-id'?: string
  'data-section'?: string
  'data-card-name'?: string
  'data-index'?: number
}
